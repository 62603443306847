@use 'globals';
@use 'components';
@use 'util' as *;

#fem-smd {
    padding: rem(40) rem(15);
    background-color: var(--very-dark-blue-bg);
    color: var(--white-text);
}

.section-heading {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @include breakpoint-down(small) {
       display: block;

        &__col-left {
            border-bottom: rem(1) solid var(--dark-grayish-blue-text);
            padding-bottom: rem(20);
        }

        &__col-right {
            display: flex;
            justify-content: space-between;
        }
    }

     h1 {
        margin-bottom: 0;
    }

    .followers-count {
        font-size: rem(13);
        color: var(--desaturated-blue-text);
        font-weight: 700;
    }

    .section-heading__col-right {
        display: flex;

        @include breakpoint-down(small) {
            margin-top: 20px;
        }
    }
}

.sub-title {
    margin-top: rem(60);

    h2 {
        margin-bottom: rem(10);
    }
}

.credits {
    margin-top: rem(60);
    font-size: 14px;
    line-height: 1;
    color: var(--desaturated-blue-text);

    p {
        margin: rem(10) 0;
    }
}





.swing-bottom-fwd {  width: 120px;
    background-color: rgba(44, 38, 102, 0.198);
    -webkit-box-shadow: 0px 0px 0px 1px #000000; 
    box-shadow: 0px 0px 0px 1px #000;
    height: 50%;
    left: 0;
    position: absolute;
    top: 0px;
    width: 100%;
    
	-webkit-animation: swing-bottom-fwd 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) infinite forwards;
    animation: swing-bottom-fwd 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) infinite forwards;
}
/* ----------------------------------------------
 * Generated by Animista on 2021-10-11 16:1:46
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation swing-bottom-fwd
 * ----------------------------------------
 */
// @-webkit-keyframes swing-bottom-fwd {
//     0% {
//         -webkit-transform: rotateX(0);
//         transform: rotateX(0);
       
//         -webkit-transform-origin: bottom;
//         transform-origin: bottom;
//     }
//     100% {
//         -webkit-transform: rotateX(-180deg);
//         transform: rotateX(-180deg);
//         transform: skewX(3px);
//         -webkit-transform-origin: bottom;
//         transform-origin: bottom;
//     }
// }
@keyframes swing-bottom-fwd {
    0% {
        -webkit-transform: rotateX(0);
        transform: rotateX(0);
        transform: skew(5deg);
        -webkit-transform-origin: bottom;
        transform-origin: bottom;
    }
    100% {
        -webkit-transform: rotateX(-180deg);
        transform: rotateX(-180deg);
        transform: skew(180deg 20deg);
        -webkit-transform-origin: bottom;
        transform-origin: bottom;
    }
}
  