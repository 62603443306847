:root {
  --font-inter: "Inter", sans-serif;
}

:root {
  --counter-grayish-blue: hsl(237, 18%, 59%);
  --counter-soft-red: hsl(345, 95%, 68%);
  --counter-white: hsl(0, 0%, 100%);
  --counter-dark-desaturated-blue: hsl(236, 21%, 26%);
  --counter-very-dark-blue: hsl(235, 16%, 14%);
  --counter-very-dark-black-blue: hsl(234, 17%, 12%);
  --counter-shadow-blue: hsl(235, 20%, 32%);
}

html {
  font-size: 100%;
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  font-family: var(--font-inter);
}

h1, h2, h3 {
  margin-top: 0;
}

a, a:hover, a:visited, a:active, a:focus {
  text-decoration: none;
}

.container {
  max-width: 69.375rem;
  margin: 0 auto;
}

#fem-counter {
  position: relative;
  background-image: url(../../../images/bg-stars.svg);
  background-color: var(--counter-very-dark-blue);
  background-size: contain;
  background-repeat: no-repeat;
  font-family: "Red Hat Text", sans-serif;
  position: relative;
  padding: 160px 0 0;
}
@media (max-width: 31.25em) {
  #fem-counter {
    background-position: 49% 20px;
    background-size: auto;
  }
}
#fem-counter::after {
  content: "";
  background-image: url(../../../images/pattern-hills.svg);
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 11.75rem;
  background-repeat: no-repeat;
  background-size: cover;
}
@media (max-width: 31.25em) {
  #fem-counter::after {
    height: 9.75rem;
    left: -645px;
  }
}
#fem-counter h2 {
  color: #fff;
  text-align: center;
  margin: 0;
  font-size: 1.375rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 12px;
  text-indent: 12px;
}
@media (max-width: 71.8125em) {
  #fem-counter h2 {
    font-size: 1.125rem;
  }
}
@media (max-width: 31.25em) {
  #fem-counter h2 {
    font-size: 1rem;
    letter-spacing: 4px;
    padding: 0 40px;
  }
}
#fem-counter .counter-social-icons {
  display: flex;
  position: relative;
  justify-content: center;
  z-index: 1;
  padding: 50px;
}
@media (max-width: 31.25em) {
  #fem-counter .counter-social-icons {
    padding: 25px;
  }
}
#fem-counter .counter-social-icons .icon {
  margin: 0 15px;
  cursor: pointer;
}
#fem-counter .counter-social-icons .icon:hover {
  filter: invert(52%) sepia(31%) saturate(1304%) hue-rotate(299deg) brightness(100%) contrast(97%);
}

.counter-wrapper {
  padding: 115px 0 250px;
}
@media (max-width: 31.25em) {
  .counter-wrapper {
    padding: 70px 0 250px;
  }
}
.counter-wrapper .counter__numbers {
  text-align: center;
  color: var(--counter-soft-red);
  font-weight: 700;
  font-size: 5.3125rem;
}
@media (max-width: 71.8125em) {
  .counter-wrapper .counter__numbers {
    font-size: 3.3125rem;
  }
}
@media (max-width: 31.25em) {
  .counter-wrapper .counter__numbers {
    font-size: 2.0625rem;
  }
}
.counter-wrapper .counter__numbers .count-num {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0 1.25rem;
  background: linear-gradient(0deg, var(--counter-shadow-blue) 49%, var(--counter-dark-desaturated-blue) 48%);
  padding: 0.9375rem;
  border-radius: 8px;
  -webkit-box-shadow: 0px 8px 7px 1px #000000;
  box-shadow: 0px 8px 7px 1px #000000;
  width: 9.1875rem;
  height: 8.75rem;
}
@media (max-width: 71.8125em) {
  .counter-wrapper .counter__numbers .count-num {
    width: 6.1875rem;
    height: 5.75rem;
    margin: 0 0.625rem;
  }
}
@media (max-width: 31.25em) {
  .counter-wrapper .counter__numbers .count-num {
    margin: 0 5px;
    width: 65px;
    height: 58px;
  }
}
.counter-wrapper .counter__numbers .count-num::before {
  content: "";
  position: absolute;
  height: 2px;
  background-color: rgba(41, 37, 37, 0.507);
  top: 50%;
  left: 0;
  right: 0;
  display: block;
  box-shadow: 0px 1px 0px 0px rgba(92, 92, 92, 0.2);
}
.counter-wrapper .counter__numbers .count-num .count-num-inner::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0px;
  transform: translateY(-44%);
  height: 10px;
  width: 6px;
  background-color: var(--counter-very-dark-black-blue);
  border-bottom-right-radius: 60px;
  border-top-right-radius: 60px;
}
.counter-wrapper .counter__numbers .count-num .count-num-inner::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translateY(-44%);
  height: 10px;
  width: 6px;
  background-color: var(--counter-very-dark-black-blue);
  border-bottom-left-radius: 60px;
  border-top-left-radius: 60px;
}
.counter-wrapper .counter__numbers .count-num .count-text {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -3.125rem;
  font-size: 0.875rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.375rem;
  text-indent: 6px;
  color: var(--counter-grayish-blue);
}
@media (max-width: 31.25em) {
  .counter-wrapper .counter__numbers .count-num .count-text {
    font-size: 0.4347rem;
    bottom: -1.5rem;
    letter-spacing: 0.15rem;
  }
}

#fem-smd {
  padding: 2.5rem 0.9375rem;
  background-color: var(--very-dark-blue-bg);
  color: var(--white-text);
}

.section-heading {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 31.25em) {
  .section-heading {
    display: block;
  }
  .section-heading__col-left {
    border-bottom: 0.0625rem solid var(--dark-grayish-blue-text);
    padding-bottom: 1.25rem;
  }
  .section-heading__col-right {
    display: flex;
    justify-content: space-between;
  }
}
.section-heading h1 {
  margin-bottom: 0;
}
.section-heading .followers-count {
  font-size: 0.8125rem;
  color: var(--desaturated-blue-text);
  font-weight: 700;
}
.section-heading .section-heading__col-right {
  display: flex;
}
@media (max-width: 31.25em) {
  .section-heading .section-heading__col-right {
    margin-top: 20px;
  }
}

.sub-title {
  margin-top: 3.75rem;
}
.sub-title h2 {
  margin-bottom: 0.625rem;
}

.credits {
  margin-top: 3.75rem;
  font-size: 14px;
  line-height: 1;
  color: var(--desaturated-blue-text);
}
.credits p {
  margin: 0.625rem 0;
}

.swing-bottom-fwd {
  width: 120px;
  background-color: rgba(44, 38, 102, 0.198);
  -webkit-box-shadow: 0px 0px 0px 1px #000000;
  box-shadow: 0px 0px 0px 1px #000;
  height: 50%;
  left: 0;
  position: absolute;
  top: 0px;
  width: 100%;
  -webkit-animation: swing-bottom-fwd 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) infinite forwards;
  animation: swing-bottom-fwd 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) infinite forwards;
}

/* ----------------------------------------------
 * Generated by Animista on 2021-10-11 16:1:46
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */
/**
 * ----------------------------------------
 * animation swing-bottom-fwd
 * ----------------------------------------
 */
@keyframes swing-bottom-fwd {
  0% {
    -webkit-transform: rotateX(0);
    transform: rotateX(0);
    transform: skew(5deg);
    -webkit-transform-origin: bottom;
    transform-origin: bottom;
  }
  100% {
    -webkit-transform: rotateX(-180deg);
    transform: rotateX(-180deg);
    transform: skew(180deg 20deg);
    -webkit-transform-origin: bottom;
    transform-origin: bottom;
  }
}